
import _ from "underscore";
import { Component, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import AddJourneyDrawer from "@/components/sms/AddJourneyDrawer.vue";
import * as CardServices from "@/api/helpers/Card";
import * as ColumnServices from "@/api/helpers/Column";
import System from "@/models/System";
import * as SystemServices from "@/api/helpers/System";
import EditJourneyDrawer from "@/components/sms/EditJourneyDrawer.vue";
import JourneyStepsExpandTable from "@/components/sms/JourneyStepsExpandTable.vue";
import Card from "@/models/Card";
import { bool2IconFilter, bool2IconFilterStarred, daysFilter } from "@/filters";
import { CatalogItem } from "@/models/Catalog";
import Notify from "@/utils/notifications";
import { OfferType } from "@/models/Card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Divider from "primevue/divider";
import CardDrawer from "@/components/TheCardDrawer.vue";
import CardConfigDrawer from "@/components/CardConfigDrawer.vue";
import * as CardSettingServices from "@/api/helpers/CardSetting";
import Columns from "@/models/Column";
import { Count } from "@/api/Gondor";
import { KeySystem } from "@/api/helpers/KeySystem";
import draggable from "vuedraggable";

interface SortParams {
  columns: Record<string, unknown>;
  order: string | null;
  prop: string;
}
interface Params {
  limit: number;
  offset: number;
  query: {
    sender?: string;
    content?: string;
    "country-code"?: string;
    name?: string;
    sort?: string;
  };
}

export interface Payload<T> {
  count: number;
  rows: T[];
}
@Component({
  components: {
    DeletePopover,
    JourneyStepsExpandTable,
    AddJourneyDrawer,
    EditJourneyDrawer,
    CardDrawer,
    CardConfigDrawer,
    draggable,
    Divider,
    DataTable,
    Column,
    ColumnGroup,
  },
  filters: {
    bool2IconFilter,
    bool2IconFilterStarred,
    daysFilter,
  },
})
export default class SMSJourneyView extends Vue {
  headers = [
    { text: "", value: "dragId", sortable: false },
    { text: "", value: "data-table-expand", sortable: false },
    { text: "ID", value: "id", sortable: false },
    { text: "", value: "starred", sortable: false },
    { text: "Nombre", value: "name", sortable: false },
    { text: "Imagen", value: "image", sortable: false },
    { text: "Activa", value: "active", sortable: false },
    { text: "Reglas", value: "rules", sortable: false },
  ];
  expanded = [];
  fullscreenLoading = true;
  showAddDrawer = false;
  showEditDrawer = false;
  currentPage = 1;
  systemId = 1;
  offerTypeId = null;
  cardDrawer = false;
  paramsActive = true;
  showConfigDrawer = false;
  activeSystems: Array<System> = [];
  offerTypes: Array<OfferType> = [];
  visible = false;
  cardSettings: CardSettingServices.CardSetting[] = [];
  updtType = "";
  count = 0;
  systemName = "";
  configLoading = false;
  showImprovement = false;
  offersExist = false;
  card = new Card();
  selectedCard: any = {};
  columns: Array<Columns> = [];
  pageNumberOfElementsOptions = [10, 25, 50, 100, 250, 500, 1000];
  defaultParams = {
    query: {
      name: undefined,
      sender: undefined,
      content: undefined,
      "country-code": undefined,
      sort: "-created-at",
    },
    limit: 10,
    offset: 0,
  };
  params: Params = _.clone(this.defaultParams);
  currentItem: Card | null = null;
  items = {} as Payload<Card>;
  sortedItems = {} as Payload<Card>;
  countries: CatalogItem<string>[] = [];
  keys: Count<KeySystem> = {
    count: 0,
    rows: [],
  };

  getSystems() {
    SystemServices.find({
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.activeSystems = res.data.data;
        if (this.activeSystems.length && this.activeSystems[0].id) {
          const systemId = sessionStorage.getItem("systemId");
          this.systemId = systemId
            ? parseInt(systemId)
            : this.activeSystems[0].id;
        }
        this.getCards();
        this.getOfferTypes(this.systemId);
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  openAddDrawer() {
    this.showAddDrawer = true;
  }

  closeAddDrawer(value: boolean) {
    this.showAddDrawer = value;
    this.currentItem = null;
  }

  closeEditDrawer(value: boolean) {
    this.showEditDrawer = value;
    this.currentItem = null;
  }

  async getCards(page = this.currentPage) {
    this.currentPage = page;
    this.fullscreenLoading = true;
    const systemId = this.systemId;
    const pickedSystem = _.find(this.activeSystems, (s: System) => {
      return s.id == systemId;
    });
    sessionStorage.setItem("systemId", systemId.toString());
    if (pickedSystem) {
      this.systemName = pickedSystem.name;
      this.showImprovement = pickedSystem.showImprovement;
    }
    CardServices.find({
      order: "order:asc",
      limit: 100,
      offset: 0,
      systemId: this.systemId,
      offerTypeId: this.offerTypeId === null ? undefined : this.offerTypeId,
      active: this.paramsActive,
      orderByOffer: true,
    })
      .then(res => {
        const cards = res.data.data;
        _.each(cards, (c: Card) => {
          c.url = c.urls[0].url;
        });
        this.count = cards.length;
        if (this.params.limit < this.count) {
          const start = (page - 1) * this.params.limit;
          const end = start + this.params.limit;
          this.sortedItems.rows = [...cards.slice(start, end)];
        } else {
          this.sortedItems.rows = cards;
        }
        this.offersExist = this.sortedItems.rows.some(item => item.offerType);
        this.$forceUpdate();
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });

    CardServices.find({
      order: "order:asc",
      limit: 100,
      offset: 0,
      systemId: this.systemId,
      offerTypeId: this.offerTypeId === null ? undefined : this.offerTypeId,
      active: this.paramsActive,
    })
      .then(res => {
        const cards = res.data.data;
        this.count = cards.length;
        this.items.rows = cards;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getOfferOrder(offerType) {
    let customParams;
    try {
      customParams = JSON.parse(offerType.customParams);
    } catch (e) {
      console.error("Error al parsear customParams:", e);
      return Infinity;
    }

    let orderParam;

    if (Array.isArray(customParams)) {
      orderParam = customParams.find(param =>
        Object.prototype.hasOwnProperty.call(param, "order")
      );
    } else if (customParams && typeof customParams === "object") {
      orderParam =
        "order" in customParams ? { order: customParams.order } : undefined;
    } else {
      orderParam = undefined;
    }

    const baseOrder = orderParam ? orderParam.order : 0;
    const isActive = this.getOfferActive(offerType);

    return !isActive ? baseOrder + 10000 : baseOrder;
  }

  getOfferActive(offerType) {
    let customParams;
    try {
      customParams = JSON.parse(offerType.customParams);
    } catch (e) {
      console.error("Error al parsear customParams:", e);
      return false;
    }

    let activeParam;

    if (Array.isArray(customParams)) {
      activeParam = customParams.find(param =>
        Object.prototype.hasOwnProperty.call(param, "active")
      );
    } else if (customParams && typeof customParams === "object") {
      activeParam =
        "active" in customParams ? { active: customParams.active } : undefined;
    } else {
      activeParam = undefined;
    }

    return activeParam ? activeParam.active : null;
  }

  async changeSystem(page = this.currentPage) {
    this.getOfferTypes(this.systemId);
    this.offerTypeId = null;
    this.getCards(1);
  }

  getOfferTypes(systemId: number) {
    SystemServices.offerType(systemId, {
      order: "name:asc",
    })
      .then(res => {
        this.offerTypes = res.data.data.rows;
        this.offerTypes.unshift({ id: null, name: "Todas" });
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  updateCardOrder(cardsOrder: Array<number | undefined>) {
    this.fullscreenLoading = true;
    CardServices.order({
      systemId: this.systemId,
      cards: cardsOrder,
    })
      .then(() => {
        Notify.successful("Se modificó el orden de las tarjetas.");
        this.getCards();
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  editCard(id: number) {
    this.getColumns();
    this.card = _.filter(this.sortedItems.rows, (card: any) => {
      return card.id === id;
    })[0];
    this.updtType = "card";
    this.closeDrawer(true);
  }

  editRule(id: any) {
    this.getColumns();
    this.card = _.filter(this.items.rows, (card: any) => {
      return card.id === id;
    })[0];
    this.updtType = "rule";
    this.closeDrawer(true);
  }

  deleteCard(id: number) {
    this.fullscreenLoading = true;
    CardServices.destroy(id)
      .then(res => {
        this.getSystems();
        Notify.successful(`${res.data.data.name} eliminada exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  closeDrawer(value: boolean) {
    this.cardDrawer = value;
  }

  getColumns() {
    ColumnServices.find({
      order: "name",
      system: this.systemId,
    })
      .then(res => {
        this.columns = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  showConfDrawer(id: number, name: string) {
    // this.card.id = id;
    // this.card.name = name;
    this.selectedCard = {
      id,
      name,
    };
    this.showConfigDrawer = true;
    this.getKeys();
    this.getCardSettings(id);
  }

  closeConfigDrawer(value: boolean) {
    this.showConfigDrawer = value;
  }

  copyToClipboard(url: string) {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = url;
    input.select();
    document.execCommand("copy", false);
    input.remove();
    Notify.successful("Url copiado en el portapapeles.");
  }

  redirect(url: string) {
    window.open(url, "_blank");
  }

  getKeys() {
    SystemServices.findKeys(this.systemId, {
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.keys = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCardSettings(cardSelected: number) {
    this.configLoading = true;
    CardSettingServices.find(cardSelected)
      .then(res => {
        const rows = _.map(res.data.data.rows, setting => {
          const keys = _.filter(this.keys.rows, (item: any) => {
            return item.dataType == "boolean" && item.key == setting.key;
          });
          if (keys.length) {
            if (setting.value == "0") setting.value = false;
            else if (setting.value == "1") setting.value = true;
          }
          return setting;
        });
        this.cardSettings = rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.configLoading = false;
      });
  }

  changeActiveSelect() {
    this.getCards(1);
  }

  created() {
    const index = "2.1";
    const title = "Tarjetas";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getSystems();
  }

  draggingIndex = null;

  dragStart(index: any) {
    this.draggingIndex = index;
  }

  drop(index: number, offerTypeId: number) {
    if (this.draggingIndex !== null && this.draggingIndex !== index) {
      const grouped = _.groupBy(
        this.sortedItems.rows,
        card => card.offerType?.id || "without_offerType"
      );

      let clonedItems: Card[] = [];
      let updatedRows: Card[] = [];
      let indexOrder: number | undefined;

      if (offerTypeId) {
        clonedItems = [...grouped[offerTypeId]];
        indexOrder = clonedItems[index].order;
        const targetIds = clonedItems.map(c => c.id);
        const firstIndex = this.items.rows.findIndex(item =>
          targetIds.includes(item.id)
        );

        const lastIndex =
          this.items.rows.length -
          1 -
          [...this.items.rows]
            .reverse()
            .findIndex(item => targetIds.includes(item.id));

        const result = this.items.rows.slice(firstIndex, lastIndex + 1);
        updatedRows = [...result];
      } else {
        clonedItems = [...this.sortedItems.rows];
        updatedRows = [...this.items.rows];
      }

      clonedItems[this.draggingIndex].order = clonedItems[
        index
      ].order = indexOrder;

      for (let i = 0; i < updatedRows.length; i++) {
        const updateItem = clonedItems.find(row => {
          if (row.id === updatedRows[i].id) {
            return true;
          }
          return false;
        });
        if (!updatedRows[i].order) {
          updatedRows[i].order == 1000;
        }
        if (updateItem && updateItem.order! != updatedRows[i].order) {
          if (indexOrder && updateItem.order! == indexOrder) {
            updatedRows[i].order = updateItem.order! + 1;
          } else if (indexOrder && updatedRows[i].order! >= indexOrder) {
            updatedRows[i].order = updateItem.order! - 1;
          }
        } else if (
          ((indexOrder && updatedRows[i].order! > indexOrder) ||
            (indexOrder && updatedRows[i].order! == indexOrder)) &&
          this.draggingIndex + 1 != index
        ) {
          updatedRows[i].order = updatedRows[i].order! + 2;
        }
      }
      updatedRows.sort((a, b) => (a.order || Infinity) - (b.order || Infinity));
      const updatedCards = updatedRows.map(c => c.id);
      this.fullscreenLoading = true;
      this.updateCardOrder(updatedCards);
    }
    this.draggingIndex = null;
  }
}
